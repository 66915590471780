import React from "react";
import { graphql } from 'gatsby'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

import Layout from "../components/layout"
import SEO from "../components/seo"
import SidebarRecentPosts from "../components/sidebar-recent-posts";
import RichText from "../components/rich-text"
import Image from "../components/image"

const About = ({data}) => (
    <Layout page='chi-siamo'>
    <SEO title="Chi siamo" 
          description={documentToPlainTextString(data.about.shortDescription ? data.about.shortDescription.json : '')} 
          image={data.about.cover.fluid? data.about.cover.fluid.src: ''}
    />
      <div className="container container-flex">
        <main role="main">
          <Image fluid={data.about.cover.fluid} file={data.about.cover.file} alt="some text" classStyle="post-image" />
          <h2>{data.about.title}</h2>

          <RichText text={data.about.content.json}  />

        </main>

        <aside className="sidebar">
          <SidebarRecentPosts />
        </aside>
      </div>
    </Layout>
  );

export default About;

export const aboutQuery = graphql`
 query about {
  about: contentfulSimpleLife(slug: {eq: "chi-siamo"}) {
    slug
    title
    content {
      json
    }
    shortDescription {
      json
    }
    cover {
      file {
            url
            fileName
            contentType
            details {
              image {
                height
                width
              }
              size
            }
          }
      fluid {
        aspectRatio
        sizes
        src
        srcSet
      }
    }
  }
}

`

